
$(function () {
  $('.collapse').on('shown.bs.collapse', function (e) {
    const $card = $(this).closest('.accordion-item')
    const $open = $($(this).data('parent')).find('.collapse.show')
    let additionalOffset = 0
    if ($card.prevAll().filter($open.closest('.accordion-item')).length !== 0) {
      additionalOffset = $open.height()
      console.log(additionalOffset)
    }
    $('html,body').animate({
      scrollTop: $card.offset().top - additionalOffset - 70
    }, 300)
  })
})
